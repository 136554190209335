import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

import './App.css';

const portalId = process.env.REACT_APP_HS_PORTAL_ID
const formId = process.env.REACT_APP_HS_FORM_ID
const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`

const App = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [email, setEmail] = React.useState(null)
  const [hasError, setHasError] = React.useState(false)
  const [emailSubmitted, setEmailSubmitted] = React.useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    setHasError(null)

    const response = await fetch(
      url,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          fields: [
            {
              name: 'email',
              value: email
            }
          ]
        })
      }
    ).catch((err) => {
      console.error('Error conectando con servidor')
      setHasError('Error conectando con servidor')
    })
    if (response.status === 200) {
      setEmailSubmitted(true)
    } else {
      console.error('Error registrando correo')
      setHasError('Error registrando correo')
    }
    setIsLoading(false)
  }

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  return (
    <div className="App">
      <div id="gradiente"/>
      <header>
        <div>
          <img src="/images/logo.svg" id="logo" alt="Mala Hora logo" />
        </div>
        <div id="titulo">¡Llegó tu hora! ¡La Mala Hora!</div>
      </header>
      <section>
        <div id="catchphrase">
          Ven y trabajemos juntos. <strong>Mala Hora Studios</strong> es un nuevo estudio de grabación y producción musical ubicado en Barranquilla, Colombia
        </div>
      </section>
      <section id="email">
        <div className="alineamiento">
          <strong>PONGÁMONOS EN CONTACTO</strong>
          {emailSubmitted ? (
            <React.Fragment>
              <div id="gracias">
                Gracias! Muy pronto nos pondremos en contacto
              </div>
              {hasError && (
                <div id="error">
                  <small>{hasError}</small>
                </div>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <form onSubmit={handleSubmit}>
                <div>
                  <input name="email" type="email" placeholder="Tu Email" disabled={isLoading} onChange={handleEmail}/>
                </div>
                <div>
                  <button type="submit" disabled={isLoading}>Enviar</button>
                </div>
              </form>
              {hasError && (
                <div id="error">
                  <small>{hasError}</small>
                </div>
              )}
              <div className="small">
                <small>Te escribiremos lo más pronto posible</small>
              </div>
            </React.Fragment>
          )}
        </div>
      </section>
      <section id="socials">
        <ul>
          <li>
            <a href="https://www.facebook.com/malahorastudio" target="new"><FaFacebook /></a>
          </li>
          <li>
            <a href="https://www.instagram.com/malahorastudio/" target="new"><FaInstagram /></a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/mala-hora-studios" target="new"><FaLinkedin /></a>
          </li>
        </ul>
      </section>
    </div>
  );
}

export default App;
